import React, { useEffect, useContext, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  Container,
  Grid,
  Box,
  Dialog,
  IconButton,
  Typography,
  Backdrop,
  CircularProgress,
  Link,
  useMediaQuery,
  DialogTitle
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import OtpInput from 'react-otp-input';
import {
  verifyOtp,
  resendOtp,
  setAlertInfo,
  setIsVerified,
  setIsOpen,
  setUserData
} from '../../store/actions';
//import styles
import styles from './styles';
import axios from 'axios';

const OtpDialog = (props) => {
  const {
    id,
    open,
    handleClose,
    rEmail,
    rPhone,
    history,
    setIsOpen,
    setAlertInfo,
    userData,
    selectedSeats,
    listAllShow,
    userToken,
    setUserData,
    user,
    totalAmount
  } = props;
  console.log(totalAmount?.amount);
  console.log(totalAmount);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = styles();

  const formRef = useRef(null);

  const generateInvoiceNumber = () => {
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      .replace(/\//g, '');

    const randomDigits = Math.floor(Math.random() * 100000)
      .toString()
      .padStart(5, '0');
    return `IN${formattedDate}${randomDigits}`;
  };

  const [formData, setFormData] = useState({
    access_key: process.env.REACT_APP_CYBER_SOURCE_ACCESS_KEY,
    profile_id: process.env.REACT_APP_CYBER_SOURCE_PUBLIC_KEY,
    transaction_uuid: generateInvoiceNumber(),
    signed_field_names:
      'access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency',
    unsigned_field_names: '',
    signed_date_time: new Date().toISOString().slice(0, 19) + 'Z',
    locale: 'en-us',
    transaction_type: 'sale',
    reference_number: new Date().getTime(),
    amount: totalAmount,
    currency: process.env.REACT_APP_CYBER_SOURCE_CURRENCY
  });

  // test
  useEffect(() => {
    if (totalAmount) {
      setFormData({
        ...formData,
        amount: totalAmount
      });
    }
  }, [totalAmount]);

  const [otp_code, Setotp_code] = useState('');
  const [haserror, SetHaserror] = useState(false);
  const [mainHelper, SetMainHelper] = useState({
    isError: false,
    helperText: ''
  });
  const [resendClicked, SetresendClicked] = useState(false);
  const [disableotp, SetOtpDisable] = useState(false);
  const [resend_status, SetResendStatus] = useState({
    isError: false,
    helpertext: '',
    msg_type: ''
  });
  const [backdropOpen, SetbackdropOpen] = useState(false);
  // Alert calls for 2sec visibility
  const onShowAlert = (status, helperText, type) => {
    SetResendStatus({
      isError: status,
      helpertext: helperText,
      msg_type: type
    });
    window.setTimeout(() => {
      SetResendStatus({
        isError: false,
        helpertext: '',
        msg_type: ''
      });
    }, 3000);
  };

  const handleChange = (otp) => {
    SetHaserror(false);
    SetMainHelper({ isError: false, helperText: '' });
    Setotp_code(otp);
  };

  useEffect(() => {
    if (otp_code.length == 6) {
      SetOtpDisable(true);
      SetbackdropOpen(true);

      if (rPhone) {
        props
          .verifyOtp(rPhone, rEmail, otp_code)
          .then(async (output) => {
            SetbackdropOpen(false);
            if (!output?.isSuccess) {
              SetMainHelper({ isError: true, helperText: output.msg });
              SetOtpDisable(false);
            } else {
              setIsVerified(true);
              SetbackdropOpen(true);
              const url = `${process.env.REACT_APP_BE_URL}/bookings/book`;
              axios
                .post(url, {
                  fullName: userData?.name,
                  email: userData?.email,
                  phone: userData?.phone,
                  seatInfo: selectedSeats,
                  nic: userData?.nic,
                  listAllShowId: listAllShow?.showDateAndTime,
                  userToken: userToken?.userToken,
                  formData: formData
                })
                .then((res) => {
                  setTimeout(() => {
                    SetbackdropOpen(false);
                  }, 5000);
                  setFormData({
                    ...formData,
                    signature: res?.data?.data?.id
                  });
                  console.log(formData);
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                })
                .catch((err) => {
                  SetbackdropOpen(false);
                  const alertinfo = {
                    content: err.response.data.message,
                    dialogType: '',
                    title: 'A kind Info'
                  };
                  setAlertInfo(alertinfo);
                  setIsOpen(true);
                });
            }
          })
          .catch((error) => {});
      } else if (rEmail) {
        props
          .verifyOtp(rPhone, rEmail, otp_code)
          .then(async (output) => {
            SetbackdropOpen(false);
            if (!output?.isSuccess) {
              SetHaserror(true);
              SetMainHelper({ isError: true, helperText: output.msg });
              SetOtpDisable(false);
            } else {
              setIsVerified(true);
              SetbackdropOpen(true);
              const url = `${process.env.REACT_APP_BE_URL}/bookings/book`;
              axios
                .post(url, {
                  fullName: userData?.name,
                  email: userData?.email,
                  phone: userData?.phone,
                  seatInfo: selectedSeats,
                  nic: userData?.nic,
                  listAllShowId: listAllShow?.showDateAndTime,
                  userToken: userToken?.userToken,
                  formData: formData
                })
                .then((res) => {
                  setTimeout(() => {
                    SetbackdropOpen(false);
                  }, 5000);
                  setFormData({
                    ...formData,
                    signature: res?.data?.data?.id
                  });
                  console.log(formData);
                  if (formRef.current) {
                    formRef.current.submit();
                  }
                })
                .catch((err) => {
                  SetbackdropOpen(false);
                  const alertinfo = {
                    content: err.response.data.message,
                    dialogType: '',
                    title: 'A kind Info'
                  };
                  setAlertInfo(alertinfo);
                  setIsOpen(true);
                });
            }
          })
          .catch((error) => {});
      }
    }
  }, [otp_code]);

  const resendOtp = () => {
    SetMainHelper({ isError: false, helperText: '' });
    SetHaserror(false);
    SetresendClicked(true);
    Setotp_code('');
    SetOtpDisable(false);

    if (rPhone && rEmail)
      props
        .resendOtp(rPhone, rEmail)
        .then((output) => {
          if (!output?.isSuccess) {
            SetHaserror(true);
            SetMainHelper({ isError: true, helperText: output.msg });
            SetOtpDisable(true);
          }
          setTimeout(() => {
            SetresendClicked(false);
          }, 1000);
        })
        .catch((error) => {});
  };

  return (
    <div>
      <Dialog
        onClose={() => {
          Setotp_code('');
          SetHaserror(false);
          SetOtpDisable(false);
          SetMainHelper({
            isError: false,
            helperText: ''
          });
          handleClose();
        }}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="sm"
        open={open}
        aria-labelledby={id}
        maxHeight="sm"
        style={fullScreen ? { top: 'auto', bottom: 0 } : {}}
        scroll={'body'}>
        <Backdrop className={classes.backdrop} open={backdropOpen}>
          <CircularProgress color="primary" size={70} thickness={5} />
        </Backdrop>
        <DialogTitle id={id}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              handleClose();
              Setotp_code('');
              SetHaserror(false);
              SetOtpDisable(false);
              SetMainHelper({
                isError: false,
                helperText: ''
              });
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {/* <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleClose();
            Setotp_code('');
            SetHaserror(false);
            SetOtpDisable(false);
            SetMainHelper({
              isError: false,
              helperText: ''
            });
          }}></DialogTitle> */}
        <Box>
          <Container>
            <Grid container>
              <Grid item xs={12} className={classes.item_content}>
                <div className={classes.otp_wrapper}>
                  <Typography variant="h6" className={classes.succes_title}>
                    Otp verification
                  </Typography>
                  <Typography
                    component="h6"
                    variant="h6"
                    className={classes.apply_job_sub_title}>
                    {rEmail && rPhone
                      ? `We have sent otp code to your email ${rEmail} and phone ${rPhone}. please enter one of them to proceed.`
                      : rEmail
                      ? `We have sent otp code to your email ${rEmail}. please enter otp code to proceed.`
                      : rPhone
                      ? `We have sent otp code to your phone ${rEmail}. please enter otp code to proceed.`
                      : `We have sent otp code to your verified mediums. please enter otp code.`}
                  </Typography>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <OtpInput
                      inputStyle={classes.input_otp_style}
                      value={otp_code}
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span>-</span>}
                      isInputNum={true}
                      hasErrored={haserror}
                      errorStyle={classes.error_otp}
                      focusStyle={haserror ? '' : classes.focus_otp}
                      isDisabled={disableotp}
                      disabledStyle={classes.disable_style}
                      shouldAutoFocus
                    />
                  </div>

                  {mainHelper.isError && (
                    <p className={classes.main_error_text}>
                      {mainHelper.helperText}
                    </p>
                  )}
                  {resend_status.isError && (
                    <p
                      className={
                        resend_status.msg_type
                          ? classes.main_success_text
                          : classes.main_error_text
                      }>
                      {'\u2003'}
                      {resend_status.helpertext}
                    </p>
                  )}
                  <div className={classes.otp_resend_wrapper}>
                    {!resendClicked ? (
                      <>
                        {' '}
                        <Typography
                          variant="h6"
                          className={classes.small_title}>
                          I didn't receive the code -
                        </Typography>
                        <Link component="button" onClick={resendOtp}>
                          Resend
                        </Link>
                      </>
                    ) : (
                      <Typography variant="h6" className={classes.small_title}>
                        Sending...
                      </Typography>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
          <form
            ref={formRef}
            action={process.env.REACT_APP_CYBER_SOURCE_URL}
            method="post">
            <input
              type="hidden"
              name="access_key"
              value={formData.access_key}
            />
            <input
              type="hidden"
              name="profile_id"
              value={formData.profile_id}
            />
            <input
              type="hidden"
              name="transaction_uuid"
              value={formData.transaction_uuid}
            />
            <input
              type="hidden"
              name="signed_field_names"
              value={formData.signed_field_names}
            />
            <input
              type="hidden"
              name="unsigned_field_names"
              value={formData.unsigned_field_names}
            />
            <input
              type="hidden"
              name="signed_date_time"
              value={formData.signed_date_time}
            />
            <input type="hidden" name="locale" value={formData.locale} />
            <input type="hidden" name="signature" value={formData.signature} />
            <input
              type="hidden"
              name="transaction_type"
              value={formData.transaction_type}
            />
            <input
              type="hidden"
              name="reference_number"
              value={formData.reference_number}
            />
            <input type="hidden" name="amount" value={formData.amount} />
            <input type="hidden" name="currency" value={formData.currency} />
          </form>
        </Box>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authState.isAuthenticated,
  userData: state.userDataState.userData,
  totalAmount: state.userDataState.totalAmount,
  userToken: state.userDataState.userToken,
  selectedSeats: state.userDataState.selectedSeats,
  listAllShow: state.listAllShowState.listAllShow,
  user: state.authState.user
});
export default connect(mapStateToProps, {
  verifyOtp,
  setIsVerified,
  resendOtp,
  setIsOpen,
  setUserData,
  setAlertInfo
})(OtpDialog);
